import React, { createContext, useEffect, useState, ReactNode } from "react";
import { useCookies, Cookies } from "react-cookie";
import { DayOfWeek, ContextInterface } from "./AuthContentTypes";
import { TicketFeedback } from "../fetchRequests/feeback-support/ts/getTS";
import { streamTS, callStatusTS } from "../utils/stream/streamTS";
import { Link, useNavigate } from "react-router-dom";
import { EXPIRATION_DURATION } from "../constants";
import {
  /*  GetSingleProduct,
  Products, */
  CartTS,
  GetSingleProduct,
} from "../fetchRequests/products/ts/getTS";
import { CurrentProgramContextState } from "../fetchRequests/programs/ts/getTS";
import {
  addGlobalProductToCart,
  removeGlobalProductFromCart,
  updateProductCartQuantity,
} from "./globalProducCartLogic/globalProducCartLogic";

import { loginUserTS } from "../fetchRequests/users/ts/postTS";
import { handleLoginNewUser } from "../utils/authUser/loginNewUser/loginNewUser";
import { Week } from "../fetchRequests/programs/ts/getTS";

/* import { GetCompanyPackage } from "../fetchRequests/company/ts/getTS"; */

const defaultContextValue: ContextInterface = {
  preferredLocale: null,
  setPreferredLocale: () => {},
  loginNewUser: (user: loginUserTS) => {},
  logOutExistingUser: (navigation_url?: string) => {},
  updateLoggedInUser: (newCredentials: any, current?: loginUserTS | null) => {},
  cookies: new Cookies(),
  setCookie: () => {},
  removeCookie: () => {},
  cookiesTesting: new Cookies(),
  setCookiesTesting: () => {},
  removeCookiesTesting: () => {},
  showSideNavMobile: false,
  setShowSideNavMobile: () => {},
  currentLoggedIn: {} as loginUserTS | null,
  profileImage: "",
  setProfileImage: () => {},
  /*  bannerImage: "",
  setBannerImage: () => {}, */
  /*   folderToUpload: "",
  setFolderToUpload: () => {}, */
  showUploadProfileImage: false,
  setShowUploadProfileImage: () => {},
  showUploadCompanyMainImage: false,
  setShowUploadCompanyMainImage: () => {},
  showDeleteConfirmationPopUp: false,
  setShowDeleteConfirmationPopUp: () => {},
  showErrorConfirmationPopUp: false,
  setShowErrorConfirmationPopUp: () => {},
  showErrorMessage: "",
  setShowErrorMessage: () => {},
  showUploadBannerImage: false,
  setShowUploadBannerImage: () => {},
  showImageSliderFullScreen: false,
  setShowImageSliderFullScreen: () => {},
  showAddEventPopUp: false,
  setShowAddEventPopUp: () => {},
  calendarEventDate: null!,
  setCalendarEventDate: () => {},
  cartFinal: {
    quantity: 0,
    total: "0.00",
    products: [],
  },
  setCartFinal: () => {},
  updateCartQuantity: (global_product_id: string, new_quantity: number) => {},
  addToCart: () => {},
  removeFromCart: () => {},
  cartFinalTotal: 0,
  successfulCartPaymentReset: () => {},
  showSearchPopUp: false,
  setShowSearchPopUp: () => {},
  showVerifyEmailMessage: false,
  setShowVerifyEmailMessage: () => {},
  showUpdateConfirmation: false,
  setShowUpdateConfirmation: () => {},
  showGenericConfirmation: false,
  setShowGenericConfirmation: () => {},
  newProfileNeeded: false,
  setNewProfileNeeded: () => {},
  sectionSelected: 0,
  setSectionSelected: () => {},
  exericseProgramsectionSelected: 0,
  setExericseProgramSectionSelected: () => {},
  newlyCreatedCompanyId: null,
  setNewlyCreatedCompanyId: () => {},
  companyType: 0,
  setCompanyType: () => {},
  serverTime: new Date(),
  setServerTime: () => {},
  tickets: [],
  setTickets: () => {},
  showNewTicket: false,
  setShowNewTicket: () => {},
  stream: {
    who: null,
    streams: null,
  },
  remoteStream: {
    who: null,
    streams: null,
    peerConnection: null,
  },
  handleLocalStreamChange: () => {},
  handleRemoteStreamChange: () => {},
  handleUpdateCallStatus: () => {},
  callStatus: {
    current: "",
    video: "off",
    audio: "off",
    audioDevice: "",
    videoDevice: "",
    shareScreen: false,
    haveMedia: false,
  },
  exerciseProgramState: {
    program_id: "",
    numberOfWeeks: [
      {
        week_number: 0,
        position: 0,
        week_id: undefined,
        program_id: undefined,
        title: "",
        days: [],
      },
    ],
    numberOfDays: 0,
    numberOfSections: 0,
    mainTemplate: {
      template_id: "",
      template_name: "",
    },
  },
  setCurrentExerciseProgramData: () => {},
};

const Context = createContext<ContextInterface>(defaultContextValue);
interface Props {
  children?: ReactNode;
  // any props that come into the component
}

export function ConstProvider({ children }: Props) {
  const navigate = useNavigate();

  const [serverTime, setServerTime] = useState(new Date());
  const [preferredLocale, setPreferredLocaleState] = useState<string | null>(
    "en-gb"
  );

  const setPreferredLocale = (locale: string) => {
    setPreferredLocaleState(locale);
    console.log("set cookies");
    localStorage.setItem("preferredLocale", locale); // Store the locale in localStorage
  };

  const [profileImage, setProfileImage] = useState("");
  /* const [bannerImage, setBannerImage] = useState(""); */
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [showSideNavMobile, setShowSideNavMobile] = useState<boolean>(false);
  const [currentLoggedIn, setCurrentLoggedIn] = useState<loginUserTS | null>(
    null
  );
  const [tickets, setTickets] = useState<TicketFeedback>([]);
  const [showNewTicket, setShowNewTicket] = useState<boolean>(false);
  const [cookiesTesting, setCookiesTesting, removeCookiesTesting] = useCookies([
    "token",
  ]);
  const [showDeleteConfirmationPopUp, setShowDeleteConfirmationPopUp] =
    useState<boolean>(false);
  const [showErrorConfirmationPopUp, setShowErrorConfirmationPopUp] =
    useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string>("");
  const [showUploadProfileImage, setShowUploadProfileImage] =
    useState<boolean>(false);
  const [showUploadCompanyMainImage, setShowUploadCompanyMainImage] =
    useState<boolean>(false);
  const [showUploadBannerImage, setShowUploadBannerImage] =
    useState<boolean>(false);
  const [showImageSliderFullScreen, setShowImageSliderFullScreen] =
    useState<boolean>(false);
  const [showAddEventPopUp, setShowAddEventPopUp] = useState<boolean>(false);
  const [calendarEventDate, setCalendarEventDate] = useState<DayOfWeek>({
    hour: "",
    day: "",
    date: new Date(),
    timeStamp: "",
  });
  const [showSearchPopUp, setShowSearchPopUp] = useState<boolean>(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] =
    useState<boolean>(false);
  const [showGenericConfirmation, setShowGenericConfirmation] =
    useState<boolean>(false);

  /*################################## LOGIN USER ##############################################*/

  const loginNewUser = (user: loginUserTS) => {
    try {
      const success = handleLoginNewUser(user, setCurrentLoggedIn);

      if (!success) {
        throw new Error("User login failed due to invalid input.");
      }

      // Only set the cookie if the login function succeeds
      setCookie("token", user.token, {
        maxAge: EXPIRATION_DURATION,
        path: "/", // Ensure it's accessible across the site
        secure: process.env.NODE_ENV === "production", // Secure in production
        sameSite: "lax", // Adjust if needed
      });
    } catch (error) {
      console.error("Error in loginNewUser:", error);
      throw error; // Ensure error propagates
    }
  };

  /*################################## LOG OUT EXISTING USER ##############################################*/
  const logOutExistingUser = (navigation_url?: string) => {
    removeCookie("token", { path: "/" });
    setCurrentLoggedIn(null);
    setTimeout(() => {
      if (navigation_url) {
        navigate(navigation_url);
      }
    }, 100);
  };

  /*################################## UPDATE LOGIN USER ##############################################*/
  const updateLoggedInUser = (
    newCredentials: Partial<loginUserTS>,
    current?: loginUserTS | null
  ) => {
    if (!newCredentials || typeof newCredentials !== "object") {
      console.error("Invalid or missing new credentials:");
      throw new Error("Invalid or missing new credentials");
    }

    if (current && typeof current !== "object") {
      console.error("Invalid current user data:");
      throw new Error("Invalid current user data");
    }

    if (typeof setCurrentLoggedIn !== "function") {
      console.error("Error: setCurrentLoggedIn function is not available.");
      throw new Error(
        "Cannot update user state: setCurrentLoggedIn is undefined."
      );
    }

    const updatedUser: loginUserTS = {
      verified_credentials_status:
        newCredentials.verified_credentials_status ??
        current?.verified_credentials_status ??
        0,
      verified_credentials_submitted:
        newCredentials.verified_credentials_submitted ??
        current?.verified_credentials_submitted ??
        "",
      avatar_url: newCredentials.avatar_url ?? current?.avatar_url ?? "",
      profile_time_zone:
        newCredentials.profile_time_zone ?? current?.profile_time_zone ?? "",
      language: newCredentials.language ?? current?.language ?? "",
      profile_type_ref:
        newCredentials.profile_type_ref ?? current?.profile_type_ref ?? 0,
      email_verified:
        newCredentials.email_verified ?? current?.email_verified ?? false,
      profile_id: newCredentials.profile_id ?? current?.profile_id ?? "",
      profile_slug: newCredentials.profile_slug ?? current?.profile_slug ?? "",
      profile_username:
        newCredentials.profile_username ?? current?.profile_username ?? "",
      num_associated_profiles: Number(
        newCredentials.num_associated_profiles ??
          current?.num_associated_profiles ??
          0
      ),
      token: newCredentials.token ?? current?.token ?? "",
    };

    console.log(updatedUser);

    setCurrentLoggedIn(updatedUser);
  };

  /*################################## CREATE NEW COMPANY LOGIC ##############################################*/
  const [companyType, setCompanyType] = useState<number>(1);
  const [newProfileNeeded, setNewProfileNeeded] = useState<boolean>(false);
  const [sectionSelected, setSectionSelected] = useState<number>(() => {
    if (!localStorage.getItem("newlyCreatedCompanySectionIndex")) {
      return 1;
    }
    const newlyCreatedComp = localStorage.getItem(
      "newlyCreatedCompanySectionIndex"
    );
    if (newlyCreatedComp) {
      return JSON.parse(newlyCreatedComp);
    }
  });

  const [exericseProgramsectionSelected, setExericseProgramSectionSelected] =
    useState<number>(() => {
      if (!localStorage.getItem("newlyCreatedExercisePromgamSectionIndex")) {
        return 1;
      }
      const newlyCreatedProgram = localStorage.getItem(
        "newlyCreatedExercisePromgamSectionIndex"
      );
      if (newlyCreatedProgram) {
        return JSON.parse(newlyCreatedProgram);
      }
    });

  // Add new company tabs
  const [newlyCreatedCompanyId, setNewlyCreatedCompanyId] = useState<any>(null);
  useEffect(() => {
    setNewlyCreatedCompanyId(() => {
      const newCompId = localStorage.getItem("newlyCreatedCompanyId");
      return newCompId ? Number(newCompId) : null;
    });
  }, []);
  /*################################## CREATE NEW COMPANY LOGIC END ##############################################*/
  /*################################## Exercise Program state ###################### */

  const [exerciseProgramState, setExerciseProgramState] =
    useState<CurrentProgramContextState>({
      program_id: "",
      numberOfWeeks: [
        {
          week_number: 0,
          position: 0,
          week_id: undefined,
          program_id: undefined,
          title: "",
          days: [],
        },
      ],
      numberOfDays: 0,
      numberOfSections: 0,
      mainTemplate: {
        template_id: "",
        template_name: "",
      },
    });

  const setCurrentExerciseProgramData = (
    key: keyof CurrentProgramContextState,
    value: any
  ) => {
    setExerciseProgramState((prevState) => ({ ...prevState, [key]: value }));
  };
  /*################################## VIDEO LOGIC ##############################################*/
  const [stream, setStream] = useState<streamTS | null>({
    who: null,
    streams: null,
  });

  const [remoteStream, setRemoteStream] = useState<streamTS | null>({
    who: null,
    streams: null,
  });

  const handleLocalStreamChange = (
    who: string,
    newLocalStream: MediaStream
  ) => {
    setStream({
      who: who, // Assuming 'local' for the 'who' property, change as needed
      streams: newLocalStream,
    });
  };

  const handleRemoteStreamChange = (
    who: string,
    newRemoteMediaStream: MediaStream,
    peerConnection: any | null
  ) => {
    setRemoteStream((cur) => ({
      who: who, // Assuming 'remote' for the 'who' property, change as needed
      streams: newRemoteMediaStream,
      peerConnection: peerConnection,
    }));
  };

  const [callStatus, setCallStatus] = useState<callStatusTS>({
    current: "",
    video: "off",
    audio: "off",
    audioDevice: "",
    videoDevice: "",
    shareScreen: false,
    haveMedia: false,
  });

  const handleUpdateCallStatus = (
    partialStatus: Partial<callStatusTS>
  ): void => {
    setCallStatus((prevStatus) => {
      return {
        ...prevStatus,
        ...partialStatus,
      };
    });
  };
  /*################################## VIDEO LOGIC END ##############################################*/

  // ###################### HANDLE SHOPPING CART ###################### //
  const [cartFinal, setCartFinal] = useState<CartTS | null>(null);
  const [cartFinalTotal, setCartFinalTotal] = useState<number>(0);

  useEffect(() => {
    setCartFinalTotal(cartFinal?.quantity || 0);
  }, [cartFinal]);

  useEffect(() => {
    const cartToal = localStorage.getItem("cartTotal");
    const cartFinal = localStorage.getItem("cartFinal");
    if (cartToal && cartFinal) {
      setCartFinalTotal(JSON.parse(cartToal));
      setCartFinal(JSON.parse(cartFinal));
    }
  }, []);

  const addToCart = (product: GetSingleProduct) => {
    const updatedCartFinal = addGlobalProductToCart(product, cartFinal);
    localStorage.setItem("cartFinal", JSON.stringify(updatedCartFinal));
    localStorage.setItem(
      "cartTotal",
      JSON.stringify(updatedCartFinal.quantity)
    );
    setCartFinal(updatedCartFinal);
  };

  const removeFromCart = (global_product_id: string) => {
    if (!cartFinal || !global_product_id) {
      return;
    }
    const updatedCartFinal = removeGlobalProductFromCart(
      cartFinal,
      global_product_id
    );
    localStorage.setItem("cartFinal", JSON.stringify(updatedCartFinal));
    localStorage.setItem(
      "cartTotal",
      JSON.stringify(updatedCartFinal.quantity)
    );
    setCartFinal(updatedCartFinal);
  };

  const updateCartQuantity = (
    global_product_id: string,
    new_quantity: number
  ) => {
    if (!cartFinal || !global_product_id || !new_quantity) {
      return;
    }
    const updatedCartFinal = updateProductCartQuantity(
      cartFinal,
      global_product_id,
      new_quantity
    );

    localStorage.setItem("cartFinal", JSON.stringify(updatedCartFinal));
    localStorage.setItem(
      "cartTotal",
      JSON.stringify(updatedCartFinal.quantity)
    );
    setCartFinal(updatedCartFinal);
  };

  const successfulCartPaymentReset = () => {
    setCartFinal(null);
    localStorage.removeItem("cartFinal");
    localStorage.setItem("cartTotal", JSON.stringify(0));
  };
  // ###################### END OF HANDLE SHOPPING CART ###################### //

  const [showVerifyEmailMessage, setShowVerifyEmailMessage] = useState(false);

  return (
    <Context.Provider
      value={{
        preferredLocale,
        setPreferredLocale,
        loginNewUser,
        logOutExistingUser,
        updateLoggedInUser,
        cookies,
        setCookie,
        removeCookie,
        cookiesTesting,
        setCookiesTesting,
        removeCookiesTesting,
        showSideNavMobile,
        setShowSideNavMobile,
        currentLoggedIn,
        profileImage,
        setProfileImage,
        /*   bannerImage,
        setBannerImage, */
        /*   folderToUpload,
        setFolderToUpload, */
        showUploadProfileImage,
        setShowUploadProfileImage,
        showUploadBannerImage,
        setShowUploadBannerImage,
        showUploadCompanyMainImage,
        setShowUploadCompanyMainImage,
        showImageSliderFullScreen,
        setShowImageSliderFullScreen,
        showAddEventPopUp,
        setShowAddEventPopUp,
        calendarEventDate, //
        setCalendarEventDate,
        cartFinal,
        setCartFinal,
        updateCartQuantity,
        addToCart,
        removeFromCart,
        cartFinalTotal,
        successfulCartPaymentReset,
        showSearchPopUp,
        setShowSearchPopUp,
        showUpdateConfirmation,
        setShowUpdateConfirmation,
        showGenericConfirmation,
        setShowGenericConfirmation,
        /*   checkIfEmailIsVerified, */
        showVerifyEmailMessage,
        setShowVerifyEmailMessage,
        showDeleteConfirmationPopUp,
        setShowDeleteConfirmationPopUp,
        showErrorConfirmationPopUp,
        setShowErrorConfirmationPopUp,
        showErrorMessage,
        setShowErrorMessage,
        newProfileNeeded,
        setNewProfileNeeded,
        sectionSelected,
        setSectionSelected,
        exericseProgramsectionSelected,
        setExericseProgramSectionSelected,
        newlyCreatedCompanyId,
        setNewlyCreatedCompanyId,
        companyType,
        setCompanyType,
        serverTime,
        setServerTime,
        tickets,
        setTickets,
        showNewTicket,
        setShowNewTicket,
        stream,
        remoteStream,
        handleLocalStreamChange,
        handleRemoteStreamChange,
        handleUpdateCallStatus,
        callStatus,
        exerciseProgramState,
        setCurrentExerciseProgramData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
