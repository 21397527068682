import React from "react";
import ExplainHover from "../explainHover/ExplainHover";
import styles from "./headerTitle.module.css";

interface HeaderProps {
  level: 1 | 2 | 3 | 4 | 5 | 6; // Specifies the header level (h1-h6)
  children: React.ReactNode; // The content inside the header
  tooltip?: string; // Optional tooltip for the hover explanation
  className?: string; // Custom CSS class names
  style?: React.CSSProperties; // Inline styles for the header
}

const HeaderTitle: React.FC<HeaderProps> = ({
  level = 1, // Default to h1
  children,
  tooltip,
  className = "",
  style = {},
}) => {
  const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements; // Dynamically determine the header tag

  return (
    <div className={`${styles.headerContainer}`}>
      <HeaderTag className={styles.headerContainerTitle}>
        {children}
        {tooltip && <ExplainHover>{tooltip}</ExplainHover>}
      </HeaderTag>
    </div>
  );
};

export default HeaderTitle;
