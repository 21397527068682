import spinner from "../../../../src/img/loader/Spinner-1s-200px.svg";
import SmartImage from "../image/smartImage/SmartImage";

const LoadingSpinner = () => {
  return (
    <div className="loaderSpinnerPagesContainer">
      <div className="loaderSpinnerPages">
        <SmartImage
          src={spinner}
          fallbackSrc={spinner}
          alt="Loading Spinner"
          loading="eager"
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;
