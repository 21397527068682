import { useState } from "react";
import styles from "./processingButton.module.css";

interface processingButtonTS {
  isProcessing: boolean;
  buttonTitle: string;
  icon: string;
  onClickFnc?: () => void;
  color?: string;
  isDisabled?: boolean;
}

const ProcessingButton = ({
  isProcessing,
  buttonTitle,
  icon,
  onClickFnc,
  color = "#019d9d",
  isDisabled,
}: processingButtonTS) => {
  const handleClick = () => {
    if (onClickFnc) {
      onClickFnc();
    }
  };

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(color);

  return (
    <>
      <button
        className={styles.btnProcessing}
        type="submit"
        disabled={isProcessing || isDisabled}
        onClick={handleClick}
        style={{ backgroundColor: buttonBackgroundColor }}
      >
        {isProcessing ? (
          <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <>
            {buttonTitle}
            <i
              className={`${icon} ${styles.processingIcon} pointer aria-hidden="true"`}
            ></i>
          </>
        )}
      </button>
    </>
  );
};

export default ProcessingButton;
