import { loginUserTS } from "../../../fetchRequests/users/ts/postTS";

type SetCurrentLoggedIn = (user: loginUserTS | null) => void;

export const handleLoginNewUser = (
  user: loginUserTS | null | undefined,
  setCurrentLoggedIn: SetCurrentLoggedIn
): boolean => {
  if (!user || typeof user !== "object" || !user.token) {
    console.error("Invalid user object or missing token.");
    return false;
  }

  setCurrentLoggedIn(user);
  return true; // Indicate success
};
