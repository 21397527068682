import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import EmailAuthorised from "../../pages/authorisation_sub/unauthorised/EmailAuthorised";
import { ReactNode } from "react";
import { getUsersLoginSessionToken } from "../../../fetchRequests/users/fetchRequestLoginSession";
import LoadingSpinner from "../../global/loadingSpinner/LoadingSpinner";
import { ROUTES } from "../../../routes";

interface Props {
  children: ReactNode;
}

const RequireAuth = ({ children }: Props): JSX.Element => {
  const { cookies, currentLoggedIn } = useContext(AuthContext);

  if (!cookies.token) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  if (!currentLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  if (!currentLoggedIn.email_verified) {
    return <EmailAuthorised />;
  }

  return <>{children}</>;
};

export default RequireAuth;
