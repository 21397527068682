import HeaderTitle from "../../global/headerTitle/HeaderTitle";

const RequireAdminReject = (): JSX.Element => {
  return (
    <div className="center80">
      <HeaderTitle level={1}>Restricted Content</HeaderTitle>
      <p>You're not authorised to view this content.</p>
    </div>
  );
};

export default RequireAdminReject;
