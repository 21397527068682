import React, { useState, useContext } from "react";
import { useFetchErrorHandling } from "../../../fetchRequests/useFetchErrorHandling";
import Context from "../../../contexts/AuthContext";
import { resendEmailVerification } from "../../../fetchRequests/users/fetchRequestAuth";
import ProcessingButton from "../../global/buttons/processingButton/ProcessingButton";

interface ResendVerificationProps {
  setResend: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResendVerification = ({ setResend }: ResendVerificationProps) => {
  const { cookies } = useContext(Context);

  const { handleFetchError } = useFetchErrorHandling();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = () => {
    setIsProcessing(true);
    resendEmailVerification(cookies.token)
      .then(() => {
        setResend(true);
      })
      .catch((err: Error) => {
        handleFetchError(err);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <ProcessingButton
      isProcessing={isProcessing}
      buttonTitle={"Send new verification email"}
      icon={"fa fa-envelope-o"}
      onClickFnc={handleClick}
    />
  );
};

export default ResendVerification;
