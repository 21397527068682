interface AuthFetchOptions extends RequestInit {
  token?: string; // Optional token property
}

export const authFetch = async (
  url: string,
  options: AuthFetchOptions = {}
) => {
  const headers = {
    ...options.headers,
    ...(options.token ? { Authorization: options.token } : {}),
  };

  try {
    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === 204 && response.ok) {
      return {}; // Return an empty object for 204 responses
    }

    let data;
    try {
      data = await response.json();
    } catch (error) {
      console.error("JSON parsing error:", error);
      throw new Error("Invalid JSON response from server.");
    }
    if (!response.ok) {
      if (
        response.status === 401 &&
        data?.msg === "Access denied invalid session_id or session expired"
      ) {
        window.location.href = "/session-expired";
        throw new Error("Redirecting to login");
      }

      throw new Error(
        data?.msg || "An error occurred while fetching the data."
      );
    }

    return data;
  } catch (error) {
    console.error("authFetch error:", error);
    throw error; // Rethrow to allow handling in the calling function
  }
};
