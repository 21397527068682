import React, { ImgHTMLAttributes } from "react";

interface SmartImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc: string;
}

const SmartImage: React.FC<SmartImageProps> = ({
  src,
  fallbackSrc,
  alt = "image",
  loading = "lazy",
  ...props
}) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = fallbackSrc;
  };

  return (
    <img
      src={src || fallbackSrc}
      alt={alt}
      loading={loading ?? "lazy"}
      onError={handleError}
      {...props}
    />
  );
};

export default SmartImage;
