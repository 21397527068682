import { Helmet } from "react-helmet";

interface HelmetMetaProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
}

const SEOHelmetMeta = ({
  title,
  description,
  image = "https://urbacise.co.uk/images/homepage/boxer_1920.jpg",
  url = "https://urbacise.co.uk",
}: HelmetMetaProps) => {
  const finalUrl =
    url ||
    (typeof window !== "undefined"
      ? window.location.href
      : "https://urbacise.co.uk");
  const fullTitle = `${title} | Urbacise`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />

      {/* Open Graph */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={finalUrl} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:type" content="image/jpeg" />
    </Helmet>
  );
};

export default SEOHelmetMeta;
