import { ReactNode } from "react";
import styles from "./toolTip.module.css";

interface Props {
  title?: string;
  children?: ReactNode;
}

const ExplainHover = ({ children }: Props) => {
  return (
    <div className={styles.tooltipTitleWrapper}>
      <div className={styles.tooltipWrapper}>
        <span className={styles.tooltip}>?</span>
        <div className={styles.tooltipText}>{children}</div>
      </div>
    </div>
  );
};

export default ExplainHover;
