import { useState } from "react";
import HeaderTitle from "../../../global/headerTitle/HeaderTitle";
import ResendVerification from "../ResendVerification";

const EmailAuthorised = () => {
  const [resent, setResend] = useState(false);

  return (
    <div className="center80">
      <HeaderTitle level={1}>
        Please check your email inbox, you need to verify your email address
        before you can view our content.
      </HeaderTitle>
      <p>
        This is a securing feature to help prevent bots. You have 7 days to
        verify your email address, if after this time your email address is not
        verified your account will be deleted.
      </p>
      <p>
        If you have misplaced your verification email please request a new one
        below.
      </p>
      {!resent ? (
        <ResendVerification setResend={setResend} />
      ) : (
        <h2 className="error">
          <strong>Email has been resent</strong>
        </h2>
      )}
    </div>
  );
};

export default EmailAuthorised;
